import module from 'module';
import templateUrl from './misc-honor-holidays.template.html';
import './misc-honor-holidays.style.less';

class MiscHonorHolidays {
  constructor($route, confirmation, command, branchService,  layawayProductsCache) {
    this.$route = $route;
    this.confirmation = confirmation;
    this.command = command;
    this.branchService = branchService;
  }

  async $onInit() {
    this.branches = await this.branchService.toPromise();
    this.selectedBranchIds = [];

    this.options = [
      {
        name: "Pawns",
        value: "PAWNS"
      },
      {
        name: "Layaways",
        value: "LAYAWAYS"
      }
    ];
  }

  async execute() {
    const confirmation = await this.confirmation("Do you want to continue to honor holiday?");

    if (!confirmation) {
      return;
    }

    await this.command.execute('MiscHonorHoliday', {branchIds: this.selectedBranchIds, type: this.selection}).toPromise();
    this.$route.reload();
  }
}

module.component('miscHonorHolidays', {
  templateUrl,
  controller: MiscHonorHolidays
});