import _ from 'lodash';
import module from 'module';
import {Subscription} from 'rxjs/Subscription';

const templateUrl = require('./pawned-item-details-panel.template.html');
module.component('pawnedItemDetailsPanel', {
  templateUrl,
  bindings: {
    pawnItemId: '<',
    onHide: '&'
  },
  controller: function ($filter, $route, notification, fileService, pawnCache, productCache, pawnItemCache, pawnItemTypeCache,
                        pawnItemDefectTypeCache, pawnItemAttributeTypeCache, customerCache, $location, dict,
                        pawnMetalRateSingleCache, pawnService) {
    const that = this;
    const pawnItemId = that.pawnItemId;

    /**
     * This array defines display order of pawn properties.
     * Order is defined by index of element in array.
     * If property does not exist in the array below -> 1000 will be used as a default
     */
    const propertiesOrder = [
      'Box no.',
      'Item no.',
      'Branch sequence no.',
      'Pawner',
      'Request amount',
      'Category',
      'Classification',
      'Type',
      'Subtype',
      'Custom type',
      'Status',
      'Reserved to customer',
      'Sold to customer',
      'Value',
      'Overloaded value',
      'Valued on',
      'Added on',
      'Migrated',
      'PT number',
      'Migrated PT number',
      'Defects',
      'Extra description',
      'Weight',
      'Metal price per gram',
      'Original metal price per gram',
      'Auto description',
      'Karats'
    ];

    that.details = [];

    const reorderDetails = () => {
      const orderedDetails = [];
      // Rewrite ordered properties
      _.forEach(propertiesOrder, p => {
        const entry = _.find(that.details, {label: p});
        if (entry) {
          orderedDetails.push(entry);
          const idx = that.details.indexOf(entry);
          delete that.details[idx];
        }
      });
      // Rewrite remaining properties
      orderedDetails.concat(that.details);
      // Set ordered details as final list
      that.details = orderedDetails;
    };

    const addProperty = (label, property, formatter = null) => {
      // If property is not given -> return empty value
      if (!property) return;
      // Otherwise get property (using formatter if given)
      const formattedProperty = formatter ? formatter(property) : property;
      // If property is empty or === 'Other' -> leave with description
      if (!formattedProperty) return;
      // Otherwise append property
      that.details.push({label: label, value: formattedProperty});
    };

    const subscription = new Subscription();

    const pawnItemSubscription = pawnItemCache.withParam(pawnItemId).toObservable()
      .combineLatest(pawnItemTypeCache.toObservable(), (item, itemTypes) => {
        // Read item category/type/subtype
        addProperty('Category', _.find(itemTypes, {id: item.categoryId}), t => t.name);
        dict.onLoadingComplete(() =>
          addProperty('Classification', dict.getDescription('PAWN_ITEM_CLASS_CLASSIFICATION', item.classClassificationId))
        );
        addProperty('Type', _.find(itemTypes, {id: item.typeId}), t => t.name);
        addProperty('Subtype', _.find(itemTypes, {id: item.subtypeId}), t => t.name);
        addProperty('Custom type', item.customType);
        // Pass pawn item down the chain
        return item;
      })
      .combineLatest(pawnItemDefectTypeCache.toObservable(), (item, defectTypes) => {
        // Read item defects & concat it to form single property
        const defectIds = item.defectIds;
        if (defectIds && defectIds.length > 0) {
          const defectsNames = _.map(defectIds, id => _.find(defectTypes, {id: id}).name);
          addProperty('Defects', defectsNames, n => n.join(', '))
        }
        // Pass pawn item down the chain
        return item;
      })
      .combineLatest(pawnItemAttributeTypeCache.toObservable(), (item, attrTypes) => {
        // Read item attributes
        const attrs = item.attributes;
        if (attrs && attrs.length > 0) {
          const extraDescription = [];
          _.forEach(attrs, a => {
            const attrType = _.find(attrTypes, {id: a.attributeTypeId});
            if (attrType.extensible) {
              addProperty(attrType.name, a.extraType);
            } else {
              extraDescription.push(attrType.name);
            }
          });
          // If extra description attrs are defined -> add them
          if (extraDescription.length > 0) addProperty('Extra description', extraDescription.join(', '));
        }
        // Pass pawn item down the chain
        return item;
      })
      .subscribe((item) => {

        that.item = item;

        // Add common item properties
        if( item.status == 'WITHDRAWN') {
          addProperty('Box no.', item.withdrawBox ? item.withdrawBox.branchId + '-'
            + item.withdrawBox.boxNumber : null);
        }
        
        addProperty('Item no.', item.itemNumber);
        addProperty('Branch sequence no.', item.branchSequenceNumber);
        addProperty('Status', item.status, s => $filter('prettyEnum')(s));
        addProperty('Reserved on', (item.reservation || {}).reservedOn, d => $filter('prettyDateTime')(d));
        addProperty('Expiration date', (item.reservation || {}).expirationDate, d => $filter('prettyDateTime')(d));
        addProperty('Forfeited date', (item.reservation || {}).forfeitedDate, d => $filter('prettyDateTime')(d));
        addProperty('Valued on', item.valuedOn, d => $filter('prettyDateTime')(d));
        addProperty('Added on', item.addedOn, d => $filter('prettyDateTime')(d));
        addProperty('Migrated', item.migrated ? 'Yes' : null);
        addProperty('Migrated PT number', item.deprecatedProductNumber);
        addProperty('Value', item.valuation, v => $filter('php')(v));
        addProperty('Request amount', item.requestAmount, v => $filter('php')(v));
        addProperty('Overloaded value', item.overloadedValuation, v => $filter('php')(v));
        addProperty('Remarks', item.remarks);
        addProperty('Auto description', item.autoDescription);
        addProperty('Karats', item.karatsDescription);

        addProperty('Metal price per gram', item.metal ? $filter('php')(item.metal.rate.pricePerGram) : null)
        if (item.metal && item.metal.rate.parentId) {
          subscription.add(pawnMetalRateSingleCache.withParam(item.metal.rate.parentId).toObservable()
            .subscribe(metalRate => addProperty('Original metal price per gram', $filter('php')(metalRate.pricePerGram))));
        }

        that.customerId = item.soldToCustomerId || (item.reservation && item.reservation.customerId);
        if (that.customerId) {
          const customerSubscription = customerCache.profile(that.customerId).toObservable()
            .subscribe(customer => {
              switch (item.status) {
                case 'SOLD':
                  addProperty('Sold to customer', customer.effectiveName);
                  break;
                case 'RESERVATION_ACTIVE':
                case 'RESERVATION_CANCELED':
                case 'RESERVATION_EXPIRED':
                case 'RESERVATION_FORFEITED':
                  addProperty('Reserved to customer', customer.effectiveName);
                  break;
                case 'RESERVATION_CLAIMED':
                  addProperty('Claimed by customer', customer.effectiveName);
                  break;
              }
              reorderDetails();
            });
          subscription.add(customerSubscription);
        }

        // Add jewelry specific properties
        addProperty('Weight', item.weight, w => 'appx. ' + w + 'gr');
        if (item.productId) {

          const productSubscription = productCache.withParam(item.productId).toObservable()
            .subscribe(product => {
              const customerSubscription = customerCache.profile(product.customerId).toObservable()
                .subscribe(customer => {
                  addProperty('Pawner', customer.effectiveName);
                  reorderDetails();
                });
              subscription.add(customerSubscription);

              addProperty('PT number', product.productNumber);
            });
          subscription.add(productSubscription);
        }
//      Update properties order
        reorderDetails();
      });

    subscription.add(pawnItemSubscription);

    that.showFilePreview = (fileId) => {
      fileService.downloadFile(fileId, false, false)
        .success(file => {
          fileService.getMetadata(fileId)
            .success(file => {
              that.filePreviewRemarks = file.remarks;
            });
          that.showPopup = true;
          that.filePreview = window.URL.createObjectURL(file);
        })
        .error(error => {
          notification.show("Error", "Could not load appraisal file");
          that.showPopup = false;
          console.error(error);
        });
    };

    that.$onDestroy = () => {
      subscription.unsubscribe();
      if (that.filePreview) {
        window.URL.revokeObjectURL(that.filePreview);
      }
    }

    that.goToStockedItemReservationReceipt = () => {
      $location.path(`/customer/${that.customerId}/stocked-items/item/${that.pawnItemId}/reservation/receipt`);
    }

    that.goToStockedItemSaleReceipt = () => {
      $location.path(`/customer/${that.customerId}/stocked-items/item/${that.pawnItemId}/sale/receipt`);
    }

    that.moveReadyForSaleStockItemToPending = async () => {
      await pawnService.moveReadyForSaleStockItemToPending(that.pawnItemId);
      $route.reload();
    }
  }
});
