import module from 'module';

const templateUrl = require('./update-branch-asset-counter.template.html');

module.component('updateBranchAssetCounter', {
  templateUrl,
  controller: function (confirmationTemplate, http, popup, $filter, $route, command, branchService, currencyCache) {
    const that = this;

    that.command = {
      branchId: null,
      currencyId: 1,
      cashDiff: 0,
      checkDiff: 0
    };

    const s1 = branchService.toObservable().subscribe(branches => that.branches = branches);
    const s2 = currencyCache.toObservable().subscribe(currencies => that.currencies = currencies);

    that.onChange = () => {
      that.branchCash = 0;
      that.branchCheck = 0;

      that.branch = _.find(that.branches, (b) => Number(b.id) === Number(that.command.branchId));
      that.currency = _.find(that.currencies, (c) => Number(c.id) === Number(that.command.currencyId));

      if (!that.branch || !that.currency) {
        return;
      }

      http.get(`/management/branches/${that.branch.id}/counter?currencyId=${that.currency.id}`).success((counter) => {
        that.branchCash = counter.totalCash;
        that.branchCheck = counter.totalCheck;
      });
    };

    that.execute = () => {
      confirmationTemplate({
        question: 'Do you want to update the branch asset counter?',
        details: [
          {label: 'Branch name', description: $filter('prettyEnum')(that.branch.name)},
          {label: 'Increase cash by:', description: $filter('php')(that.command.cashDiff, {currency: that.currency.isoCode})},
          {label: 'Increase check by', description: $filter('php')(that.command.checkDiff, {currency: that.currency.isoCode})}
        ],
        yesCallback: () => command.execute('UpdateBranchAssetCounter', that.command).success(() => $route.reload())
      })
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
    };
  }
});
