import module from 'module';
import _ from 'lodash';
import $ from 'jquery';

const templateUrl = require('./support-case-picker.template.html');
module.component('supportCasePicker', {
  templateUrl,
  controller: function ($scope, $compile, $route, breadcrumbs) {
    const that = this;
    that.supportCase = null;

    that.availableSupportCases = {
      pawn: [{
        code: 'pawn-batch-change-auction-date',
        description: 'Batch change auction date'
      }, {
        code: 'batch-upsert-pawn-auction',
        description: 'Batch create pawn auction'
      }, {
        code: 'update-new-branch-posting-date',
        description: 'Update new branch posting date'
      }],
      misc: [{
        code: 'misc-clear-system-cache',
        description: 'Clear system cache'
      }, {
        code: 'undo-close-counters',
        description: 'Undo CLOSE COUNTERS'
      }, {
        code: 'update-product-ownership',
        description: 'Update product ownership'
      }, {
        code: 'create-access-rules',
        description: 'Create missing access rules'
      }, {
        code: 'upload-chart-of-accounts',
        description: 'Upload chart of accounts'
      }, {
        code: 'reset-ledger-balance',
        description: 'Reset GL balances'
      }, {
        code: 'update-branch-asset-counter',
        description: 'Update branch asset counter'
      }, {
        code: 'update-user-asset-counter',
        description: 'Update user asset counter'
      }, {
        code: 'push-failed-eod',
        description: 'Push failed end of day'
      }, {
        code: 'process-backdated-transactions',
        description: 'Process backdated transactions'
      }, {
        code: 'update-cif-branch',
        description: 'Update cif branch'
      }, {
        code: 'update-product-branch',
        description: 'Update product branch'
      }, {
        code: 'batch-create-holiday',
        description: 'Batch create holiday'
      }, {
        code: 'resend-failed-notifications',
        description: 'Resend failed notifications'
      }, {
        code: 'misc-honor-holidays',
        description: 'Honor holidays'
      }]
    };

    // select cases from chosen support topic
    let topic = $route.current.params['topic'];
    that.supportCases = that.availableSupportCases[topic];

    _.set(breadcrumbs, 'options', {
      'support-topic': `${topic} support`,
    });


    that.changeSupportCase = () => {
      let newScope = $scope.$new(true, $scope);
      let html = `<${that.supportCase}/>`;
      let element = $('#support-div');
      element.html($compile(html)(newScope));
    }
  }
});
