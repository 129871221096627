import 'rxjs/add/operator/filter';
import _ from 'lodash';

import module from 'module';
const templateUrl = require('./holiday-list.template.html');

module.component('holidayList', {
  templateUrl,
  bindings: {
    detailLink: '@'
  },
  controller: function($scope, $routeParams, breadcrumbs, http, branchService, notification, confirmation, postingDateService) {
    const that = this;
    that.header = [];
    that.holidays = null;
    that.minDate = null;

    const branchId = $routeParams.branch;

    const form = index => that[`form_${index}`];

    that.$onInit = async () => {
      const branchPostingDate = await postingDateService.getCurrentUserBranchPostingDate();
      that.minDate = branchPostingDate.add(1, 'days');

      that.holidays = await http.post('/management/holidays/search', {
        branchIds: [parseInt(branchId)],
        types: ['HOLIDAY', 'WEEKEND']
      }).toPromise();
    }

    const subscription = branchService.toObservable()
      .map(branches => _.find(branches, {id: ~~branchId}))
      .subscribe(branch => {
        that.branch = branch;

        _.set(breadcrumbs, 'options', {
          'holidays-label': 'Holidays > ' + branch.name
        });

        that.header = [{
            label: 'Branch name',
            text: branch.name,
          }, {
            label: 'Branch code',
            text: branch.code,
          }
        ];
      });

    that.addHoliday = () => {
      that.holidays.push({
        dirty: true,
        date: that.minDate,
        branchId: parseInt(branchId),
        type: 'HOLIDAY'
      });
    };

    that.cancelAddHoliday = () => {
      that.holidays.pop();
    };

    that.removeHoliday = idx => {
      that.holidays = _.reject(that.holidays, (value, index) => idx === index);
    };

    that.isSaveDisabled = index => form(index).$invalid && form(index).$submitted;

    that.saveHoliday = index => {
      form(index).$setSubmitted();

      if(form(index).$valid) {
        confirmation('Do you want to save this holiday?', () => {
          http.post('/management/holidays', that.holidays[index], {nxLoaderText: 'Saving holiday'})
            .success(response => {
              that.holidays[index].dirty = false;
              that.holidays[index].id = response.id;
              notification.show("Holiday has been saved.");
            })
            .error(error => notification.show("Error", `${error.errorMessage}`));
        });
      }
    };

    that.deleteHoliday = index => {
      confirmation('Do you want to remove holiday?', () => {
        http.doDelete(`/management/holidays/${that.holidays[index].id}`, {nxLoaderText: 'Removing holiday'})
          .success(ok => {
            notification.show("Holiday has been removed.");
            that.holidays.splice(index, 1);
          }).error(error => {
            notification.show("Error", `${error.errorMessage}`);
          });
      });
    };

    this.$onDestroy = () => {
      subscription.unsubscribe();
    }
  }
});
