import module from 'module';

const templateUrl = require('./pawn-honor-holidays-form.template.html');

class PawnHonorHolidaysForm {
  constructor() {
    // Do nothing
  }

  async $onInit() {
    this.honorHolidayOptions = [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false
      }
    ];

    this.honorHolidayConfigOptions = [
      {
        label: 'Keep the date',
        value: 'KEEP_DATE'
      },
      {
        label: 'Set to the next working day',
        value: 'NEXT_WORKING_DAY'
      }
    ];
  }

}

module.component('pawnHonorHolidaysForm', {
  templateUrl,
  bindings: {
    form: '=',
    pawnProduct: '='
  },
  controller: PawnHonorHolidaysForm
  }
);