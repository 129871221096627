import moment from 'moment';
import $ from 'jquery';

import module from 'module';

import templateUrl from './date-range-picker.template.html';

class DateRangePicker {
  constructor($element, $scope) {
    this.$element = $element;
    this.$scope = $scope;
    this.currentDateReference = null;
  }

  $postLink() {
    this.renderComponent();

    this.$scope.$watch('$ctrl.model', () => {
      this.renderComponent();
    }, true);
  }

  renderComponent() {
    if (this.isModelComplete()) {
      this.initializeCurrentDateReference();
      this.createDateRange();
      this.updateDates();
    }
  }

  isModelComplete() {
    return this.model && this.model.from && this.model.to;
  }

  initializeCurrentDateReference() {
    this.currentDateReference = this.model.to;
  }

  createDateRange() {
    const startDate = moment(this.model.from);
    const endDate = moment(this.model.to);
    const currentDate = moment(this.currentDateReference);

    const dateRange = this.getDateRangeInput();

    dateRange.daterangepicker({
      // no date property is supposed to be null otherwise calendar breaks up
      startDate: this.model.from ? startDate : currentDate,
      endDate: this.model.to ? endDate : currentDate,
      // dateLimit: {days: 31},
      opens: 'right',
      autoUpdateInput: false,
      ranges: {
        'Today': [currentDate, currentDate],
        '2 days': [currentDate.clone().subtract(1, 'days'), currentDate],
        '1 week': [currentDate.clone().subtract(7, 'days'), currentDate],
        '1 month': [currentDate.clone().subtract(1, 'month'), currentDate]
      },
      cancelClass: 'btn btn-danger',
      locale: {
        cancelLabel: 'Clear'
      },
    }, (start, end) => {
      this.notAppliedModel = this.createModelWithFormat(start, end, 'YYYY-MM-DD');
    });

    if (!this.resetAllowed) {
      $('.cancelBtn').hide();
    }

    dateRange.on('apply.daterangepicker', (_, dateRange) => {
      if (this.notAppliedModel.empty) {
        // in case user did 'clear' -> 'apply' it takes previously selected date
        this.notAppliedModel = this.createModelWithFormat(dateRange.startDate, dateRange.endDate, 'YYYY-MM-DD');
      }
      this.$scope.$evalAsync(() => Object.assign(this.model, this.notAppliedModel));
    });

    // disable manual editing of input
    dateRange.keydown(() => {
      return false;
    });

    // empty date-range input on cancel (clear)
    dateRange.on('cancel.daterangepicker', () => {
      this.notAppliedModel = {from: null, to: null, empty: true};
      this.$scope.$evalAsync(() => {
        Object.assign(this.model, this.notAppliedModel);
        this.getDateRangeInput().val('');
      });
    });

    return dateRange;
  }

  updateDates() {
    const {from: startDate, to: endDate} = this.model;
    this.getDateRangeInput()
      .val(this.toDateString(startDate, 'MM/DD/YYYY')
        + ' - '
        + this.toDateString(endDate, 'MM/DD/YYYY'));
  }

  getDateRangeInput() {
    return $(this.$element.find('input.daterange'));
  }

  createModelWithFormat(start, end, formatStyle) {
    return {
      from: this.toDateString(start, formatStyle),
      to: this.toDateString(end, formatStyle)
    };
  }

  toDateString(date, formatStyle) {
    return date ? moment(date).format(formatStyle) : null;
  }
}

module.component('dateRangePicker', {
  templateUrl,
  bindings: {
    model: '=',
    /**
     * When TRUE there is a special button to clear out the date effectively meaning
     * no date will be selected, otherwise button isn't available and date must be provided
     */
    resetAllowed: '<'
  },
  controller: DateRangePicker
});
