import module from 'module';
import './stock-item-batch-upload.style.less';

const templateUrl = require('./stock-item-batch-upload.template.html');
class StockItemBatchUpload {
  constructor(command) {
    this.command = command;
  }

  async upload() {
    this.successMessage = "";
    this.warningMessage = "";
    
    let fileId = this.file[0].id;
    let response = await this.command.execute('StockItemBatchUpload', {fileId: fileId}).toPromise();

    if (response && response.output) {
      let processed = response.output.processed;
      let ignoredItemCodes = response.output.ignoredItemCodes;

      if (processed && processed === 0) {
        this.warningMessage = "No item has been uploaded. Please check the stocked items page if they have been uploaded previously.";
      } else if (ignoredItemCodes && ignoredItemCodes.length) {
        let message = "The following items have not been uploaded. Please check the stocked items page if they have been uploaded previously:";

        for (let i = 0; i < ignoredItemCodes.length; i++) {
          message += `\n${i + 1}. ${ignoredItemCodes[i]}`;
        }

        this.warningMessage = message;
      } else if (processed === response.output.countInFile) {
        this.successMessage = "All items have been uploaded.";
      }
    }
  }
}

module.component('stockItemBatchUpload', {
  templateUrl,
  controller: StockItemBatchUpload
});