import module from 'module';

const templateUrl = require('./layaway-honor-holidays-form.template.html');

class LayawayHonorHolidaysForm {
  constructor() {}

  async $onInit() {
    this.honorHolidayOptions = [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false
      }
    ];

    this.honorHolidayConfigOptions = [
      {
        label: 'Keep the date',
        value: 'KEEP_DATE'
      },
      {
        label: 'Set to the next working day',
        value: 'NEXT_WORKING_DAY'
      }
    ];
  }

}

module.component('layawayHonorHolidaysForm',{
  templateUrl,
  bindings: {
    form: '=',
    layawayProduct: '='
  },
  controller: LayawayHonorHolidaysForm
  }
);